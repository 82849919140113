/**
 * = Cards
 */

.card {
    position: relative;
    .card-header{
        background: transparent;
    }

    &.hover-state{
        &:hover {
            background-color: $gray-100;
        }
    }

    .profile-cover{
        @include background-image(no-repeat, cover); 
        height: 200px;
    }

    &.notification-card{
        height: 633px;
        .list-group-timeline{
            height: 520px;
            overflow: hidden;
            overflow-y: auto;
        }
    }

    @include media-breakpoint-up(lg) {
        &.timeline-card{
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                margin-left: .75rem;
                border: 8px solid $black;
                border-color: transparent #e0e6ec #e0e6ec transparent;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
                @include transform(rotate(135deg));
                @include box-shadow($box-shadow);
            }
        }
    
        &.left-timeline-card {
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                margin-right: -5px;;
                border: 8px solid $black;
                border-color: transparent #e0e6ec #e0e6ec transparent;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
                @include transform(rotate(-45deg));
                @include box-shadow($box-shadow); 
            }
        }
    }
}

.message-wrapper,
.task-wrapper{
    .card{
        border:0;
    }
}

.customer-testimonial {
    .content-wrapper{
        &:before {
            content: "";
            position: absolute;
            top: 25px;
            left: -1px;
            margin-left: .75rem;
            border: 8px solid $black;
            border-color: transparent #e0e6ec #e0e6ec transparent;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            @include transform(rotate(135deg));
            @include box-shadow($box-shadow);
        }
    }
}

.card-footer{
    background-color:transparent;
}

.card-stats{
    padding-left:1.785rem;
}


// Card with blockquotes

.card {
    .card-blockquote {
        position: relative;
        padding: 2rem;

        .svg-bg {
            display: block;
            position: absolute;
            width: 100%;
            height: 95px;
            top: -94px;
            left: 0;
        }
    }
}

// Animated cards

.card-lift-hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}

.page-preview {

    display: block;
    position: relative;

    .show-on-hover {
        position: absolute;
        bottom: -25px;
        background: rgba($dark, .85);
        padding: 10px 0;
        border-bottom-left-radius:  $border-radius;
        border-bottom-right-radius: $border-radius;
        width: calc(100% + 28px);
        left: -14px;
        opacity: 0;
        @include transition(.2s);
    }

    &.page-preview-lg {
        .show-on-hover {
            left: -30px;
            width: calc(100% + 60px);
            bottom: -20px;
        }
    }

    &:hover {
        .show-on-hover {
            z-index: 99;
            opacity: 1;
        }
    }
}